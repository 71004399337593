import React from 'react';
import { inject, observer } from 'mobx-react';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Typography from '@material-ui/core/Typography';

class ProfileView extends React.Component {
  handleEnter = () => {
    this.props.store.loadUserPlaces();
  };
  handleClose = () => {
    this.props.store.ui.myPlacesOpened = false;
  };
  render() {
    const { store } = this.props;
    const user = store.user.data;
    const imageToShow = user.picture.replace('?sz=50', '?sz=150');

    const places = store.userPlaces.data;
    return (
      <Dialog
        fullScreen
        open={store.ui.myPlacesOpened}
        onEntered={this.handleEnter}
      >
        <AppBar>
          <Toolbar style={{ padding: 0 }}>
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          style={{
            position: 'absolute',
            top: '56px',
            width: '100vw',
            height: 'calc(100vh - 56px)',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <div style={{ textAlign: 'center', margin: '20px' }}>
            <img
              src={imageToShow}
              style={{ borderRadius: '75px' }}
              alt="Profile"
            />
          </div>
          <Typography
            style={{ textAlign: 'center', color: 'grey', fontSize: '20px' }}
          >
            {user.name}
          </Typography>

          <div
            style={{
              textAlign: 'center',
              display: this.props.store.userPlaces.isLoading ? '' : 'none',
              padding: '20px'
            }}
          >
            <CircularProgress />
          </div>

          <List mediaList>
            {places.map(place => (
              <ListItem
                dense
                button
                divider
                key={place.id}
                onClick={() => {
                  store.selectPlace(place.id);
                  store.resetPlaceUnderEdit();
                  store.ui.placeViewOpened = true;
                }}
              >
                <img src={place.previewPictureURL} alt="Place" />
                <ListItemText
                  primary={place.title.slice(0, 40)}
                  secondary={place.description.slice(0, 40)}
                  style={{ paddingLeft: '10px' }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    );
  }
}

export default inject('store')(observer(ProfileView));
