import React from 'react';
import { inject, observer } from 'mobx-react';
import { autorun, toJS } from 'mobx';

import loadMap from '../utils/gmap';

import { getIcon } from '../utils/icons';

function getMapRadius(map) {
  var bounds = map.getBounds();
  var center = map.getCenter();
  if (bounds && center) {
    var ne = bounds.getNorthEast();
    var radius = window.google.maps.geometry.spherical.computeDistanceBetween(
      center,
      ne
    );
    return Math.round(radius) || 10000;
  }
  // 10 km;
  return 2000;
}

class MapView extends React.Component {
  markers = [];
  componentDidMount() {
    loadMap().then(() => this.initMap());
  }
  initMap() {
    this.map = new window.google.maps.Map(this.mapContainer, {
      zoom: 15,
      center: toJS(this.props.store.mapPosition),
      mapTypeControl: false,
      streetViewControl: false,
      clickableIcons: false,
      zoomControl: false,
      fullscreenControl: false,
      gestureHandling: 'greedy',
      styles: [
        {
          featureType: 'poi',
          stylers: [{ visibility: 'off' }],
        },
        {
          featureType: 'poi.park',
          stylers: [{ visibility: 'on' }],
        },
        {
          featureType: 'poi.attraction',
          stylers: [{ visibility: 'on' }],
        },
      ],
    });
    this.props.store.mapPosition.radius = getMapRadius(this.map);

    window.google.maps.event.addListener(this.map, 'dragend', () => {
      const center = this.map.getCenter();
      this.props.store.setNewMapPosition({
        lng: center.lng(),
        lat: center.lat(),
        radius: getMapRadius(this.map),
      });
      this.props.store.loadPlaces({ throttle: true });
    });
    window.google.maps.event.addListener(this.map, 'zoom_changed', () => {
      const center = this.map.getCenter();
      this.props.store.setNewMapPosition({
        lng: center.lng(),
        lat: center.lat(),
        radius: getMapRadius(this.map),
      });
      this.props.store.loadPlaces({ throttle: true });
    });

    autorun(() => this.updatePlaceMarkers(), 200);
    autorun(() => this.updateUserLocation());
    autorun(() => {
      this.map.setCenter(this.props.store.mapPosition);
    });
  }
  updateUserLocation() {
    this.currentLocationMarker =
      this.currentLocationMarker ||
      new window.google.maps.Marker({
        map: this.map,
        title: 'Current location',
        clickable: false,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: '#3C8DF9',
          fillOpacity: 1,
          scale: 7,
          strokeColor: 'rgba(60, 141, 249, 0.4)',
          strokeWeight: 6,
        },
        shadow: null,
        // optimized: false,
        zIndex: 12,
      });

    const location = this.props.store.userLocation;
    this.currentLocationMarker.setPosition(
      new window.google.maps.LatLng(location.lat, location.lng)
    );
  }
  updatePlaceMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = this.props.store.filteredPlaces.map((place) => {
      var marker = new window.google.maps.Marker({
        position: place.position,
        map: this.map,
        opacity: place.score >= 1 ? 1 : 0.3,
        icon: getIcon(place.categories[0]),
        // icon: {
        //   path: window.google.maps.SymbolPath.CIRCLE,
        //   fillColor: '#DB4437',
        //   fillOpacity: 1,
        //   scale: 5,
        //   strokeColor: '#E98279',
        //   strokeWeight: 1
        // }
      });
      window.google.maps.event.addListener(marker, 'click', () => {
        if (window.justDragged) {
          return;
        }
        this.props.store.selectPlace(place.id);
        this.props.store.ui.placeViewOpened = true;
      });
      return marker;
    });
  }
  handleCurrentLocationClick = () => {
    this.props.store.moveMapToCurrentLocation();
    this.props.store.loadPlaces();
  };
  render() {
    return (
      <div
        style={{
          position: 'absolute',
          top: '56px',
          width: '100vw',
          height: 'calc(100vh - 56px)',
        }}
      >
        {/* <AddButton /> */}
        {/* <ViewNav /> */}
        <div
          ref={(node) => (this.mapContainer = node)}
          id="main-map"
          style={{
            width: '100%',
            height: '100%',
          }}
        />
        <a
          className="show-current-location"
          onClick={this.handleCurrentLocationClick}
        >
          <i className="material-icons icon">navigation</i>
        </a>
      </div>
    );
  }
}

export default inject('store')(observer(MapView));
