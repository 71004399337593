const dev = process.env.NODE_ENV !== 'production';

export function error(...args) {
  if (dev) {
    console.error.apply(console, args);
  } else {
    console.error(
      'There is an error in the code! Please use unminified version for all info.'
    );
  }
  if (process.browser && window.Rollbar) {
    window.Rollbar.error(...args);
  }
}

export function info(...args) {
  if (dev) {
    console.info.apply(console, args);
  }
}

export function warn(...args) {
  if (dev) {
    console.warn.apply(console, args);
  }
}

export function log(...args) {
  if (dev) {
    console.log.apply(console, args);
  }
}
