import * as logger from './logger';

const browserLang = window.navigator.userLanguage || window.navigator.language;

const lang = browserLang.slice(0, 2).toLowerCase() === 'ru' ? 'ru' : 'en';
// const lang = 'ru';

const map = {
  map: {
    en: 'Map',
    ru: 'Карта'
  },
  list: {
    en: 'List',
    ru: 'Список'
  },
  profile: {
    en: 'Profile',
    ru: 'Профиль'
  },
  about: {
    en: 'About',
    ru: 'Инфо'
  },
  filters: {
    en: 'Filters',
    ru: 'Фильтры'
  },
  playgrounds: {
    en: 'Playgrounds',
    ru: 'Игровые площадки'
  },
  parks: {
    en: 'Parks',
    ru: 'Парки'
  },
  entertainment: {
    en: 'Entertainment',
    ru: 'Развлечения'
  },
  cafes: {
    en: 'Cafes',
    ru: 'Кафе'
  },
  other: {
    en: 'Other',
    ru: 'Другое'
  },
  low_quality_places: {
    en: 'Low quality places',
    ru: 'Места низкого качества'
  },
  login: {
    en: 'Login',
    ru: 'Авторизация'
  },
  please_login_text: {
    en: 'Please sign in before adding a new place.',
    ru: 'Пожалуйста, зарегистрируйся, чтобы добавлять места.'
  },
  cancel: {
    en: 'Cancel',
    ru: 'Отменить'
  },
  success: {
    en: 'Success!',
    ru: 'Отлично!'
  },
  place_add_message: {
    en: 'Nice! Your place is added. Thanks for the contribution!',
    ru: 'Ура! Твоё место добавлено. Спасибо!'
  },
  fail: {
    en: 'Ops!',
    ru: 'Упс!'
  },
  place_not_add_message: {
    en: 'Ops!',
    ru: 'Упс!'
  },
  type_place_name: {
    en: 'Type place name (optional)',
    ru: 'Напиши название места (не обязательно)'
  },
  type_place_description: {
    en: "Add some description or place's tips (optional)",
    ru: 'Добавь описание или советы (не обязательно)'
  },
  place_type: {
    en: 'Select place category',
    ru: 'Выбери категорию места'
  },
  playground: {
    en: 'Playground',
    ru: 'Игровая площадка'
  },
  park: {
    en: 'Park',
    ru: 'Парк'
  },
  cafe: {
    en: 'Cafe',
    ru: 'Кафе'
  },
  delete: {
    en: 'Delete',
    ru: 'Удалить'
  },
  uploading: {
    en: 'Uploading...',
    ru: 'Выгружаю...'
  },
  tap_to_add_image: {
    en: 'Tap to add an image',
    ru: 'Нажми, чтобы добавить фото'
  },
  find_on_map: {
    en: 'Find place on map',
    ru: 'Найти на карте'
  },
  save: {
    en: 'Save',
    ru: 'Сохранить'
  },
  next: {
    en: 'Next',
    ru: 'Дальше'
  },
  wait: {
    en: 'Please, wait',
    ru: 'Пожалуйста, подожди'
  },
  photos_tip: {
    en:
      'It will be really nice if you can add some images. I will try to detect place position from your images',
    ru:
      'Будет очень здорово, если ты добавишь несколько картинок. Я попробую определить местонахождение по фотографии.'
  },
  add_place_image_title: {
    en: 'Adding new place',
    ru: 'Добавляю новое место'
  },
  add_place_map_title: {
    en: 'Where is this place?',
    ru: 'Где это место?'
  },
  add_place_type_title: {
    en: 'Choose place type',
    ru: 'Выбери тип места'
  },
  add_place_info_title: {
    en: 'Add some info',
    ru: 'Добавь информацию'
  },
  // place view
  delete_place: {
    en: 'Delete place',
    ru: 'Удалить место'
  },
  // place view
  place_deleted: {
    en: 'Place is deleted.',
    ru: 'Место удалено.'
  },
  undo: {
    en: 'UNDO',
    ru: 'Отменить'
  },
  sure_delete: {
    en: 'Are you sure you want to delete it?',
    ru: 'Ты точно хочешь удалить место?'
  },
  no: {
    en: 'No',
    ru: 'Нет'
  },
  yes_delete: {
    en: 'Yes, delete it!',
    ru: 'Да, удалить его!'
  },
  bad_place: {
    en: 'Bad place',
    ru: 'Плохое место'
  },
  sure_bad_place: {
    en: 'Do you think this place has bad quality or does not exist?',
    ru: 'Ты уверен, что это место плохого качества или не существует?'
  },
  not_bad: {
    en: 'No, it is ok.',
    ru: 'Нет, нормальное место.'
  },
  yes_bad: {
    en: 'Yes, it is bad!',
    ru: 'Да, оно плохое!'
  },
  thanks: {
    en: 'Thanks!',
    ru: 'Спасибо!'
  },
  thanks_for_contribution: {
    en: 'Thanks for the contribution! We will review this place.',
    ru: 'Спасибо за информацию! Мы проверим это место.'
  },
  open_in: {
    en: 'Open in',
    ru: 'Открыть в'
  },
  edit: {
    en: 'Edit',
    ru: 'Редактировать'
  },
  report: {
    en: 'Bad place?',
    ru: 'Ошибка?'
  },
  here_are_photos: {
    en: 'We found some photos made around this place. Here they are:',
    ru: 'Мы нашли некоторые фотографии, сделанные рядом с этим местом:'
  },
  show_my_places: {
    en: 'Show my places',
    ru: 'Показать мои места'
  },
  search: {
    en: 'Search',
    ru: 'Поиск'
  },
  // about page
  what_is_title: {
    en: 'What is GoWithKid?',
    ru: 'Что такое GoWithKid?'
  },
  what_is_text: {
    en: `
      Hello! GoWithKid is a tool that helps to find good places around
      to spend some time with a kid.
      <br/>
      GoWithKid is curated by community and it is using some public
      data for places search.
      <br/>
      If you think that some places are missed, please, login and
      submit!
    `,
    ru: `
      Привет! GoWithKid - это приложение, которое поможет найти интересные места,
      в которые можно сходить с ребёнком.
      <br/>
      Сообщество родителей помогает поддерживать приложение в актуальном состоянии.
      <br/>
      Если какого-либо места не хватает - просто авторизируйся и добавь его. Тем самым ты поможешь другим родителям!
    `
  },
  write_feedback: {
    en: 'Write feedback',
    ru: 'Обратная связь'
  },
  // WELCOME MODAL
  welcome_step_1: {
    en: `
      Hello. GoWithKid will help you to find kids playgrounds
      around your location.
      <br/>
      The app will ask your permissions for geo-position.
    `,
    ru: `
      Привет. GoWithKid поможет тебе найти детские площадки поблизости.
      <br/>
      Приложение спросит у тебя разрешение на знание твоего местонахождения.
    `
  },
  welcome_step_2: {
    en: `
      The app is curated by community of parents.
      <br/>
      If you found missing place you can add it into the app
      and help other parents!
    `,
    ru: `
      Сообщество родителей помогает поддерживать приложение в актуальном состоянии.
      <br/>
      Если какого-либо места не хватает на карте, ты можешь его добавить в приложение и тем самым помочь другим родителям.
    `
  },
  welcome_step_3: {
    en: `
      Also GoWithKid is using many sources of information to
      find places worldwide automatically.
      <br/>
      If you see inappropriate images or wrong place - just
      report the place and our filters will be better.
    `,
    ru: `
      Так же GoWithKid использует много источников информации, чтобы автоматически находить места по всему миру.
      <br/>
      Если ты видишь некорректные картинки или плохие места - просто сообщи об этом месте и наши фильтры станут лучше.
    `
  },
  login_to_continue: {
    en: `To continue, please, log in`,
    ru: `Авторизируйся, чтобы продолжить`
  },
  skip_for_now: {
    en: `Skip for now`,
    ru: `Пропустить`
  }
};

export default function text(key) {
  const word = map[key];
  if (!word) {
    logger.error(new Error(`No translating for "${key}"`));
    return '-';
  }
  return word[lang] || word.en;
}

export function translateDoorbell() {
  if (!window.doorbell) {
    return setTimeout(translateDoorbell, 300);
  }
  const strings = {
    'feedback-button-text': 'Вопросы?',

    title: 'Обратная связь',
    'intro-text': '', // Empty by default
    'feedback-textarea-placeholder':
      'Отправь нам свои комментарии или предложения...',
    'feedback-label': '',
    'email-input-placeholder': 'Твой email',
    'email-label': '',
    'attach-a-screenshot': 'Прикрепить скриншот',
    'submit-button-text': 'Отправить',
    'add-attachments-label': '',

    'message-success': 'Обратная связь отправлена! Спасибо!',
    'message-error-missing-email': 'Email обязателен',
    'message-error-invalid-email': 'Не правильный email',
    'message-error-missing-message': 'Нужно написать сообщение',
    'message-error-message-too-short': 'Сообщение слишком короткое'
  };
  window.doorbell.setOption('strings', strings);
  window.doorbell.refresh();
  window.doorbell.refresh();
}

if (lang === 'ru') {
  translateDoorbell();
}
