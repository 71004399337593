const icons = {
  playground: '/playground.png',
  park: '/forest.png',
  cafe: '/fastfood.png',
  other: '/information.png',
  entertainment: '/themepark.png'
};

export function getIcon(category) {
  return process.env.PUBLIC_URL + (icons[category] || '/information.png');
}
