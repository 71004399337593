import React from 'react';
import { inject, observer } from 'mobx-react';

import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import CircularProgress from '@material-ui/core/CircularProgress';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

class ProfileView extends React.Component {
  handleEnter = () => {
    this.props.store.loadReviewPlaces();
  };
  handleClose = () => {
    this.props.store.ui.reviewPlacesOpened = false;
  };
  render() {
    const { store } = this.props;


    const places = store.reviewPlaces.data;
    const noItems = places.length === 0 && store.reviewPlaces.isLoading === false;

    return (
      <Dialog
        fullScreen
        open={store.ui.reviewPlacesOpened}
        onEntered={this.handleEnter}
      >
        <AppBar>
          <Toolbar style={{ padding: 0 }}>
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <div
          style={{
            position: 'absolute',
            top: '56px',
            width: '100vw',
            height: 'calc(100vh - 56px)',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch'
          }}
        >
          <div
            style={{
              textAlign: 'center',
              display: this.props.store.reviewPlaces.isLoading ? '' : 'none',
              padding: '20px'
            }}
          >
            <CircularProgress />
          </div>

          {noItems && <div
            style={{
              textAlign: 'center',
              padding: '20px'
            }}
          >
            No places for review!
          </div>}

          {places.length > 0 && this.props.store.user.data.isAdmin && (
            <p style={{ textAlign: 'center' }}>
              <Button
                onClick={() => {
                  this.props.store.reviewAllPlaces();
                }}
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#48ca05', margin: '10px' }}
              >
                All places are good 👍
              </Button>
            </p>
          )}
          

          <List mediaList>
            {places.map(place => (
              <ListItem
                dense
                button
                divider
                key={place.id}
                onClick={() => {
                  store.selectPlace(place.id);
                  store.ui.placeViewOpened = true;
                }}
              >
                <img src={place.previewPictureURL} alt="Place" />
                <ListItemText
                  primary={place.title.slice(0, 40)}
                  secondary={place.description.slice(0, 40)}
                  style={{ paddingLeft: '10px' }}
                />
              </ListItem>
            ))}
          </List>
        </div>
      </Dialog>
    );
  }
}

export default inject('store')(observer(ProfileView));
