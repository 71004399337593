import * as logger from './logger';
import { isPhonegap } from './env';

function getBase() {
  if (isPhonegap()) {
    return 'https://gowithkid.com';
  }
  return '';
}

// futch is fetch with progress
function futch(url, opts = {}, onProgress) {
  return new Promise((res, rej) => {
    var xhr = new XMLHttpRequest();
    xhr.open(opts.method || 'get', url);
    for (var k in opts.headers || {}) xhr.setRequestHeader(k, opts.headers[k]);
    xhr.onload = (e) => res(e.target.responseText);
    xhr.onerror = rej;
    if (xhr.upload && onProgress) xhr.upload.onprogress = onProgress; // event.loaded / event.total * 100 ; //event.lengthComputable
    xhr.send(opts.body);
  });
}

async function parseResponse(res) {
  // if (Math.random() < 0.5) {
  //   return {
  //     error: 'Can not parse json'
  //   };
  // }
  const text = await res.text();
  if (res.status !== 200) {
    console.log(res);
    logger.error(
      new Error(
        `API error from url - ${res.url}. Status - ${res.statusText}. Message - ${text}`
      )
    );
    return {
      error: 'API ERROR',
    };
  }
  try {
    const json = JSON.parse(text);
    return json;
  } catch (e) {
    logger.error(
      new Error(
        'Can not parse response from url: ' + res.url + '. Message:' + text
      )
    );
    return {
      error: 'Can not parse json',
    };
  }
}

export async function getPlaces({ lng, lat, radius }) {
  const url = getBase() + `/api/search/?lng=${lng}&lat=${lat}&radius=${radius}`;
  const res = await fetch(url, { credentials: 'include' });
  return await parseResponse(res);
}

export async function getUserPlaces({ lng, lat, radius }) {
  const url = getBase() + `/api/user-places/`;
  const res = await fetch(url, { credentials: 'include' });
  return await parseResponse(res);
}

export async function getReviewPlaces() {
  const url = getBase() + `/api/review-places/`;
  const res = await fetch(url, { credentials: 'include' });
  return await parseResponse(res);
}

export async function reviewAllPlaces() {
  const url = getBase() + '/api/review-all-places';
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
  });
  return await parseResponse(res);
}

export async function getUser() {
  const url = getBase() + `/api/user`;
  const res = await fetch(url, { credentials: 'include' });
  return await parseResponse(res);
}

export async function savePlace(json) {
  const url = getBase() + '/api/save-place';
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify(json),
  });
  return await parseResponse(res);
}

export async function deletePlace(placeId) {
  const url = getBase() + '/api/delete-place';
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ id: placeId }),
  });
  return await parseResponse(res);
}

export async function classifyPlace(place, score) {
  const url = getBase() + '/api/classifier';
  const res = await fetch(url, {
    method: 'POST',
    credentials: 'include',
    headers: { 'content-type': 'application/json' },
    body: JSON.stringify({ place, score }),
  });
  return await parseResponse(res);
}

export async function uploadPhoto(file, onProgress) {
  const data = new FormData();
  data.append('qqfile', file);
  const res = await futch(
    getBase() + '/api/upload',
    {
      method: 'post',
      credentials: 'same-origin',
      body: data,
    },
    onProgress
  );
  return JSON.parse(res);
}
