import React from 'react';
import { inject, observer } from 'mobx-react';

import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Input from '@material-ui/core/Input';

import MenuIcon from '@material-ui/icons/Menu';
import ListIcon from '@material-ui/icons/List';
import AddIcon from '@material-ui/icons/Add';
import MapIcon from '@material-ui/icons/Map';
import Fab from '@material-ui/core/Fab';

import LinearProgress from '@material-ui/core/LinearProgress';

import LeftPanel from './LeftPanel';
import MapView from './MapView';
import ListView from './ListView';
import PlaceViewPopup from './PlaceViewPopup';
import PlaceEditPopup from './PlaceEditPopup';
import LoginPopup from './LoginPopup';
import AboutPopup from './AboutPopup';
import MyPlacesPopup from './MyPlacesPopup';
import ReviewPlacesPopup from './ReviewPlacesPopup';

import PlaceAddedNotification from './PlaceAddedNotification';
import DeleteNotification from './DeleteNotification';

import text from '../utils/i18n.js';
import loadMap from '../utils/gmap';

class App_ extends React.Component {
  componentDidMount() {
    this.props.store.setupCurrentLocation();
    loadMap().then(() => {
      this.initAutoComplete();
    });
  }
  handleAddClick = () => {
    const { store } = this.props;
    if (!store.user.data.isLogged) {
      store.ui.loginOpened = true;
    } else {
      store.ui.placeEditOpened = true;
      store.selectPlace(null);
      store.resetPlaceUnderEdit();
    }
  };
  initAutoComplete() {
    if (this.autocomplete) {
      return;
    }

    const autocompleteInput = document.querySelector('#place-search-input');
    this.autocomplete = new window.google.maps.places.Autocomplete(
      autocompleteInput
    );

    window.google.maps.event.addListener(
      this.autocomplete,
      'place_changed',
      () => {
        var place = this.autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }
        this.props.store.setNewMapPosition({
          lng: place.geometry.location.lng(),
          lat: place.geometry.location.lat(),
        });
        this.props.store.loadPlaces({ throttle: true });
      }
    );

    function fixGoogleAutocompleteTouch() {
      const containers = document.querySelectorAll('.pac-container');
      if (containers.length) {
        Array.prototype.forEach.call(containers, (node) => {
          node.setAttribute('data-tap-disabled', 'true');
          node.addEventListener('touchend', function (e) {
            e.stopPropagation();
            e.stopImmediatePropagation();
          });
          node.addEventListener('touchstart', function (e) {
            e.stopPropagation();
            e.stopImmediatePropagation();
          });
        });

        return;
      }
      setTimeout(fixGoogleAutocompleteTouch, 1000);
    }
    fixGoogleAutocompleteTouch();
  }
  render() {
    const { store } = this.props;
    return (
      <div>
        <AppBar position="fixed">
          <Toolbar style={{ padding: 0 }}>
            <IconButton
              color="inherit"
              aria-label="Menu"
              onClick={() => {
                store.ui.leftPanelOpened = true;
              }}
            >
              <MenuIcon />
            </IconButton>
            <Input
              fullWidth
              id="place-search-input"
              placeholder={text('search')}
              style={{
                color: 'white',
                borderBottom: '2px solid rgba(255, 255, 255 ,0.5)',
              }}
            />
            <IconButton
              color="inherit"
              aria-label="List"
              style={{ marginLeft: 'auto' }}
              onClick={() => {
                store.ui.listViewOpened = !store.ui.listViewOpened;
              }}
            >
              {store.ui.listViewOpened ? <MapIcon /> : <ListIcon />}
            </IconButton>
          </Toolbar>
          {store.places.isLoading && <LinearProgress color="secondary" />}
        </AppBar>

        <LeftPanel />
        <MapView />
        <ListView />
        <PlaceViewPopup />
        <PlaceEditPopup />
        {/* <WelcomePopup /> */}
        <LoginPopup />
        <AboutPopup />
        <MyPlacesPopup />
        <ReviewPlacesPopup />

        <PlaceAddedNotification />
        <DeleteNotification />
        <Fab
          color="primary"
          style={{
            position: 'absolute',
            bottom: '20px',
            right: '10px',
            zIndex: 1002,
          }}
          onClick={this.handleAddClick}
        >
          <AddIcon />
        </Fab>
      </div>
    );
  }
}

export const App = inject('store')(observer(App_));
