import React from 'react';
import { inject, observer } from 'mobx-react';

import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import text from '../utils/i18n.js';

class PlacesAdded extends React.Component {
  handleClose = () => {
    this.props.store.ui.deleteNotificationOpened = false;
  };

  handleUndoClick = () => {
    this.props.store.ui.deleteCanceled = true;
    this.props.store.ui.deleteNotificationOpened = false;
  };

  render() {
    return (
      <div>
        <Snackbar
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'left'
          }}
          open={this.props.store.ui.deleteNotificationOpened}
          autoHideDuration={6000}
          onClose={this.handleClose}
          message={<span>{text('place_deleted')}</span>}
          action={[
            <Button
              key="undo"
              color="secondary"
              size="small"
              onClick={this.handleUndoClick}
            >
              {text('undo')}
            </Button>,
            <IconButton
              key="close"
              aria-label="Close"
              color="inherit"
              onClick={this.handleClose}
            >
              <CloseIcon />
            </IconButton>
          ]}
        />
      </div>
    );
  }
}

export default inject('store')(observer(PlacesAdded));
