import React from 'react';
import { inject, observer } from 'mobx-react';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import CloseIcon from '@material-ui/icons/Close';
import Typography from '@material-ui/core/Typography';

import text from '../utils/i18n.js';

class AboutPopup extends React.Component {
  handleFeedbackClick = () => {
    window.doorbell.show();
  };
  handleClose = () => {
    this.props.store.ui.aboutOpened = false;
  };
  render() {
    return (
      <Dialog fullScreen open={this.props.store.ui.aboutOpened}>
        <AppBar>
          <Toolbar style={{ padding: 0 }}>
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
          </Toolbar>
        </AppBar>
        <Typography align="center" variant="h4" style={{ paddingTop: '76px' }}>
          {text('what_is_title')}
        </Typography>
        {text('what_is_text')
          .split('<br/>')
          .map((t, i) => (
            <Typography style={{ padding: '20px' }} key={i}>
              {t}
            </Typography>
          ))}

        <div
          style={{
            textAlign: 'center',
            paddingTop: '30px'
          }}
        >
          <Button
            variant="contained"
            color="primary"
            onClick={this.handleFeedbackClick}
          >
            {text('write_feedback')}
          </Button>
        </div>
        <Typography style={{ padding: '20px' }}>Version 1.0.1</Typography>
      </Dialog>
    );
  }
}

export default inject('store')(observer(AboutPopup));
