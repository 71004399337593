import React from 'react';
import { inject, observer } from 'mobx-react';
import * as mobx from 'mobx';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Slide from '@material-ui/core/Slide';

import Menu from '@material-ui/core/Menu';
import MenuItem from '@material-ui/core/MenuItem';
import MoreVertIcon from '@material-ui/icons/MoreVert';

import BottomNavigation from '@material-ui/core/BottomNavigation';
import BottomNavigationAction from '@material-ui/core/BottomNavigationAction';
import NavigationIcon from '@material-ui/icons/Navigation';

import text from '../utils/i18n.js';

function Transition(props) {
  return <Slide direction="up" {...props} />;
}

class PlaceViewPopup extends React.Component {
  componentDidMount() {
    mobx.extendObservable(this, {
      showClassifier: true,
      anchorEl: null,
    });

    mobx.autorun(() => {
      if (this.props.store.ui.placeViewOpened) {
        this.showClassifier = true;
      }
    });
    mobx.reaction(
      () => mobx.toJS(this.props.store.userLocation),
      () => this.updateUserLocation()
    );
    // mobx.reaction(
    //   () => mobx.toJS(this.props.store.selectedPlace),
    //   () => this.updatePlaceMarker()
    // );
  }
  initMap() {
    if (!this.mapContainer) {
      return;
    }
    console.log(this.props.store.selectedPlace);

    this.map = new window.google.maps.Map(this.mapContainer, {
      zoom: 15,
      center: this.props.store.selectedPlace.position,
      streetViewControl: false,
      draggable: false,
      mapTypeControl: false,
      clickableIcons: false,
    });

    this.updatePlaceMarker();
    this.updateUserLocation();
  }

  handleMenuClick = (event) => {
    this.anchorEl = event.currentTarget;
  };

  handleMenuClose = () => {
    this.anchorEl = null;
  };

  updatePlaceMarker() {
    if (!this.map) {
      return;
    }
    const { selectedPlace } = this.props.store;
    if (selectedPlace) {
      this.map.setCenter(selectedPlace.position);
      this.placeMarker =
        this.placeMarker ||
        new window.google.maps.Marker({
          map: this.map,
          clickable: false,
          position: selectedPlace.position,
        });
      this.placeMarker.setPosition(
        new window.google.maps.LatLng(
          selectedPlace.position.lat,
          selectedPlace.position.lng
        )
      );
    }
  }
  handleEnter = () => {
    this.initMap();
  };
  updateUserLocation() {
    if (!this.map) {
      return;
    }
    this.currentLocationMarker =
      this.currentLocationMarker ||
      new window.google.maps.Marker({
        map: this.map,
        title: 'Current location',
        clickable: false,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: '#3C8DF9',
          fillOpacity: 1,
          scale: 7,
          strokeColor: 'rgba(60, 141, 249, 0.4)',
          strokeWeight: 6,
        },
        shadow: null,
        // optimized: false,
      });

    const location = this.props.store.userLocation;
    this.currentLocationMarker.setPosition(
      new window.google.maps.LatLng(location.lat, location.lng)
    );
  }

  handleClose = () => {
    this.props.store.ui.placeViewOpened = false;
    this.currentLocationMarker = null;
    this.placeMarker = null;
  };

  handleEditClick = () => {
    this.anchorEl = null;
    this.props.store.resetPlaceUnderEdit();
    this.handleClose();
    setTimeout(() => {
      this.props.store.ui.placeEditOpened = true;
    }, 50);
  };
  handleDeleteClick = () => {
    this.anchorEl = null;
    this.props.store.requestPlaceDelete(this.props.store.selectedPlace.id);
    this.handleClose();
  };
  handleBadClick = () => {
    this.props.store.savePlaceClassify(this.props.store.selectedPlace, 0);
    this.showClassifier = false;
  };
  handleGoodClick = () => {
    this.props.store.savePlaceClassify(this.props.store.selectedPlace, 1);
    this.showClassifier = false;
  };

  handleReviewClick = () => {
    this.props.store.reviewPlace(this.props.store.selectedPlace.id);
    this.props.store.ui.placeViewOpened = false;
  };
  render() {
    const selectedPlace = this.props.store.selectedPlace || {
      photos: [],
      position: {},
    };

    const googleLink =
      'http://www.google.com/maps/place/' +
      selectedPlace.position.lat +
      ',' +
      selectedPlace.position.lng;

    const showClassifier =
      this.showClassifier &&
      this.props.store.user.data.isAdmin &&
      selectedPlace.source !== 'own' &&
      selectedPlace.source !== 'openstreet';

    const title = (selectedPlace.categories || [])
      .map((c) => text(c))
      .join(' ');

    return (
      <Dialog
        fullScreen
        open={this.props.store.ui.placeViewOpened}
        TransitionComponent={Transition}
        onEntered={this.handleEnter}
      >
        <AppBar>
          <Toolbar style={{ padding: 0 }}>
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Typography color="inherit">{title}</Typography>
            <IconButton
              color="inherit"
              aria-label="More"
              aria-owns={this.anchorEl ? 'long-menu' : null}
              aria-haspopup="true"
              onClick={this.handleMenuClick}
              style={{
                marginLeft: 'auto',
                display: selectedPlace.canEdit ? '' : 'none',
              }}
            >
              <MoreVertIcon />
            </IconButton>
            <Menu
              id="long-menu"
              anchorEl={this.anchorEl}
              open={Boolean(this.anchorEl)}
              onClose={this.handleMenuClose}
            >
              <MenuItem onClick={this.handleEditClick}>Edit</MenuItem>
              <MenuItem onClick={this.handleDeleteClick}>Delete</MenuItem>
            </Menu>
            {/* <Button
              color="inherit"
              onClick={this.handleEditClick}
              style={{ marginLeft: 'auto' }}
            >
              Edit
            </Button> */}
          </Toolbar>
        </AppBar>
        <div
          style={{
            position: 'absolute',
            top: '56px',
            width: '100vw',
            height: 'calc(100vh - 56px - 56px)',
            overflow: 'auto',
            WebkitOverflowScrolling: 'touch',
          }}
        >
          <Typography
            variant="h6"
            style={{
              padding: '10px',
              paddingTop: '20px',
            }}
          >
            {selectedPlace.title}
          </Typography>
          <Typography
            variant="subtitle1"
            style={{
              paddingTop: '20px',
              padding: '10px',
            }}
          >
            {selectedPlace.description}
          </Typography>

          {this.props.store.user.data.isAdmin && (
            <Typography
              style={{
                padding: '10px',
              }}
            >
              Source: {selectedPlace.source}
            </Typography>
          )}

          {showClassifier && (
            <p style={{ textAlign: 'center' }}>
              <Button
                onClick={this.handleBadClick}
                variant="contained"
                color="primary"
                style={{ backgroundColor: '#f2384a', margin: '10px' }}
              >
                Bad
              </Button>
              <Button
                onClick={this.handleGoodClick}
                color="primary"
                variant="contained"
                style={{ backgroundColor: '#48ca05', margin: '10px' }}
              >
                Good
              </Button>
            </p>
          )}

          {selectedPlace.type !== 'own' && (
            <Typography
              style={{
                paddingTop: '20px',
                padding: '10px',
              }}
            >
              {text('here_are_photos')}
            </Typography>
          )}
          <p>
            {selectedPlace.photos.map((src, i) => (
              <img src={src} style={{ width: '100%' }} key={src} alt="Place" />
            ))}
            {/* <img
                    src={streetviewUrl}
                    alt="Street View"
                    key={streetviewUrl}
                    style={{ width: '100%' }}
                  /> */}
          </p>

          <div
            ref={(node) => {
              this.mapContainer = node;
            }}
            style={{ height: '250px' }}
          />
        </div>
        <BottomNavigation
          style={{ position: 'fixed', bottom: 0, width: '100%' }}
          showLabels
        >
          <BottomNavigationAction
            label={text('open_in') + ' Google Maps'}
            href={googleLink}
            icon={<NavigationIcon />}
          />
        </BottomNavigation>
      </Dialog>
    );
  }
}

export default inject('store')(observer(PlaceViewPopup));
