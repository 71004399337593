import React from 'react';
import { inject, observer } from 'mobx-react';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';

const ListView = ({ store }) => (
  <div
    style={{
      position: 'absolute',
      top: '56px',
      width: '100vw',
      height: 'calc(100vh - 56px)',
      zIndex: 1001,
      background: 'white',
      display: store.ui.listViewOpened ? '' : 'none',
      overflow: 'auto',
      WebkitOverflowScrolling: 'touch'
    }}
  >
    <List>
      {store.shouldDrawList &&
        store.filteredPlaces.map(place => (
          <ListItem
            dense
            button
            divider
            key={place.id}
            style={{
              backgroundColor: store.user.data.isAdmin
                ? place.lowScore
                  ? '#FFDFDF'
                  : '#E2FFE2'
                : ''
            }}
            onClick={() => {
              store.selectPlace(place.id);
              store.resetPlaceUnderEdit();
              store.ui.placeViewOpened = true;
            }}
          >
            <img
              // set src only on open
              // it will not make API requests until tab is open
              src={store.ui.listViewOpened && place.previewPictureURL}
              alt="Place"
              style={{
                width: '100px'
              }}
            />
            <ListItemText
              primary={place.title.slice(0, 40)}
              secondary={place.description.slice(0, 40)}
              style={{ paddingLeft: '10px' }}
            />
          </ListItem>
        ))}
    </List>
  </div>
);

export default inject('store')(observer(ListView));
