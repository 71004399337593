import { isPhonegap } from './utils/env';

import { Provider } from 'mobx-react';

import './css/app.css';

import React from 'react';
import ReactDOM from 'react-dom';
import { App } from './components/App';
import createStore from './store/store';

import registerServiceWorker from './registerServiceWorker';

function start() {
  window.store = createStore();
  ReactDOM.render(
    <Provider store={window.store}>
      <App />
    </Provider>,
    document.getElementById('root')
  );
}

console.log('isPhonegap', isPhonegap());

if (isPhonegap()) {
  document.addEventListener('deviceready', function() {
    start();
    window.codePush.sync();
    document.addEventListener(
      'backbutton',
      function(e) {
        e.preventDefault();
      },
      false
    );
  });
} else {
  start();
}

const loader = document.querySelector('.spinner');
loader.parentNode.removeChild(loader);

registerServiceWorker();
