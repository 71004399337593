import React from 'react';
import { inject, observer } from 'mobx-react';
import Drawer from '@material-ui/core/Drawer';

import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import Divider from '@material-ui/core/Divider';

import text from '../utils/i18n.js';

const FilterItem = ({ checked, title, onClick }) => (
  <ListItem role={undefined} dense button onClick={onClick}>
    <Checkbox checked={checked} tabIndex={-1} disableRipple />
    <ListItemText primary={title} />
  </ListItem>
);

const LeftPanel = ({ store }) => (
  <Drawer
    open={store.ui.leftPanelOpened}
    onClose={() => {
      store.ui.leftPanelOpened = false;
    }}
  >
    <List>
      <FilterItem
        title={text('playgrounds')}
        checked={store.filters.playground}
        onClick={() => {
          console.log('trigger change');
          store.filters.playground = !store.filters.playground;
        }}
      />
      <FilterItem
        title={text('parks')}
        checked={store.filters.park}
        onClick={() => {
          store.filters.park = !store.filters.park;
        }}
      />
      <FilterItem
        title={text('entertainment')}
        checked={store.filters.entertainment}
        onClick={() =>
          (store.filters.entertainment = !store.filters.entertainment)
        }
      />
      <FilterItem
        title={text('cafes')}
        checked={store.filters.cafe}
        onClick={() => (store.filters.cafe = !store.filters.cafe)}
      />
      <FilterItem
        title={text('other')}
        checked={store.filters.other}
        onClick={() => (store.filters.other = !store.filters.other)}
      />
      <FilterItem
        title={text('low_quality_places')}
        checked={store.filters.lowQuality}
        onClick={() => (store.filters.lowQuality = !store.filters.lowQuality)}
      />
      <Divider light />
      {store.user.data.isLogged && (
        <ListItem
          button
          onClick={() => {
            store.ui.myPlacesOpened = true;
            store.ui.leftPanelOpened = false;
          }}
        >
          <ListItemText primary="My places" />
        </ListItem>
      )}
      {store.user.data.isAdmin && (
        <ListItem
          button
          onClick={() => {
            store.ui.reviewPlacesOpened = true;
            store.ui.leftPanelOpened = false;
          }}
        >
          <ListItemText primary="Review places" />
        </ListItem>
      )}
      {!store.user.data.isLogged && (
        <ListItem
          button
          onClick={() => {
            store.ui.loginOpened = true;
          }}
        >
          <ListItemText primary="Login" />
        </ListItem>
      )}

      <ListItem
        button
        onClick={() => {
          store.ui.aboutOpened = true;
          store.ui.leftPanelOpened = false;
        }}
      >
        <ListItemText primary="About" />
      </ListItem>
    </List>
  </Drawer>
);

export default inject('store')(observer(LeftPanel));
