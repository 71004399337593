import * as mobx from 'mobx';
import _ from 'lodash';
import * as api from '../utils/client-api';
import * as env from '../utils/env';
import * as logger from '../utils/logger';

const DEFAULT_CENTER = {
  lng: 37.6173,
  lat: 55.75583,
};

function whenAvailable(name, callback, interval) {
  interval || (interval = 100); // ms
  setTimeout(() => {
    if (window[name]) {
      return callback();
    } else {
      window.setTimeout(() => {
        whenAvailable(name, callback, interval);
      }, interval);
    }
  }, interval);
}

class Store {
  constructor() {
    mobx.extendObservable(this, {
      userLocation: DEFAULT_CENTER,
      fromPhonegap: false,
      mapPosition: {
        ...DEFAULT_CENTER,
        radius: 1000,
      },
      filters: {
        park: true,
        playground: true,
        entertainment: true,
        cafe: true,
        other: true,
        lowQuality: false,
      },
      places: {
        isLoading: false,
        isLoadFailed: false,
        data: [],
      },
      userPlaces: {
        isLoaded: false,
        isLoading: false,
        isLoadFailed: false,
        data: [],
      },
      reviewPlaces: {
        isLoaded: false,
        isLoading: false,
        isLoadFailed: false,
        data: [],
      },
      placeUnderEdit: {},
      user: {
        isLoading: false,
        isLoadFailed: false,
        data: {
          isAdmin: false,
          isLogged: false,
          name: 'Guest',
          picture: '',
          email: '',
        },
      },
      selectedPlaceId: null,
      // special settings to allow faster initial loading
      // on start list is disabled
      shouldDrawList: false,
      ui: {
        leftPanelOpened: false,
        placeViewOpened: false,
        placeEditOpened: false,
        welcomeOpened: false,
        loginOpened: false,
        placeAddedNotificationOpened: false,
        placeFailedNotificationOpened: false,
        deleteNotificationOpened: false,
        deleteCanceled: false,
        listViewOpened: false,
        aboutOpened: false,
        myPlacesOpened: false,
        reviewPlacesOpened: false,
      },
      get selectedPlace() {
        return (
          _.find(this.places.data, { id: this.selectedPlaceId }) ||
          _.find(this.userPlaces.data, { id: this.selectedPlaceId }) ||
          _.find(this.reviewPlaces.data, { id: this.selectedPlaceId }) ||
          this.newPlace
        );
      },
      get filteredPlaces() {
        return this.places.data.filter((place) => {
          const passCategory = !!_.find(
            place.categories,
            (cat) => this.filters[cat]
          );

          const passQuality =
            !place.lowScore || (place.lowScore && this.filters.lowQuality);

          return passCategory && passQuality;
        });
      },
    });

    this.resetPlaceUnderEdit();

    this.loadUserData();

    setTimeout(() => {
      this.shouldDrawList = true;
    }, 5000);
  }

  setupCurrentLocation(callback) {
    const options = { maximumAge: 0, timeout: 10000, enableHighAccuracy: true };

    const setupWatch = () => {
      navigator.geolocation.watchPosition(
        (position) => {
          this.userLocation = {
            lat: position.coords.latitude,
            lng: position.coords.longitude,
          };
        },
        () => {},
        options
      );
    };

    navigator.geolocation.getCurrentPosition(
      (position) => {
        env.markGeoAsAsked();
        this.userLocation = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
        };

        this.mapPosition = {
          lat: position.coords.latitude,
          lng: position.coords.longitude,
          radius: this.mapPosition.radius,
        };
        this.loadPlaces();
        setupWatch();
        callback && callback();
      },
      (e) => {
        env.markGeoAsAsked();
        this.loadPlaces();
        setupWatch();
        callback && callback();
        logger.error(e);
      },
      options
    );
  }

  selectPlace(placeId) {
    this.selectedPlaceId = placeId;
  }

  async loadPlaces({ throttle } = {}) {
    const loadTimestamp = Date.now();
    this.loadTimestamp = loadTimestamp;
    this.places.isLoading = true;
    this.places.isLoadFailed = false;
    if (throttle) {
      await new Promise((resolve) => setTimeout(resolve, 3000));
    }
    if (loadTimestamp !== this.loadTimestamp) {
      return;
    }
    const data = await api.getPlaces({
      lat: this.mapPosition.lat,
      lng: this.mapPosition.lng,
      radius: this.mapPosition.radius,
    });
    if (loadTimestamp !== this.loadTimestamp) {
      return;
    }
    this.places.isLoading = false;
    this.places.isLoadFailed = !!data.error;
    this.places.data = data.data || this.places.data;
  }

  async loadUserPlaces() {
    this.userPlaces.isLoading = true;
    this.userPlaces.isLoadFailed = false;
    const data = await api.getUserPlaces({
      lat: this.mapPosition.lat,
      lng: this.mapPosition.lng,
      radius: this.mapPosition.radius,
    });
    this.userPlaces.isLoaded = !data.error;
    this.userPlaces.isLoading = false;
    this.userPlaces.isLoadFailed = !!data.error;
    this.userPlaces.data = data.data || this.places.data;
  }

  async loadReviewPlaces() {
    this.reviewPlaces.isLoading = true;
    this.reviewPlaces.isLoadFailed = false;
    const data = await api.getReviewPlaces();
    this.reviewPlaces.isLoaded = !data.error;
    this.reviewPlaces.isLoading = false;
    this.reviewPlaces.isLoadFailed = !!data.error;
    this.reviewPlaces.data = data.data || [];
  }

  async loadUserData() {
    this.user.isLoading = true;
    this.user.isLoadFailed = false;
    const data = await api.getUser();
    this.user.isLoading = false;
    this.user.isLoadFailed = !!data.error;
    if (data.error) {
      return;
    }
    this.user.data = {
      ...this.user.data,
      ...data.data.user,
    };
    if (data.data.user.isLogged) {
      env.markAsWasLogged();
      whenAvailable('doorbell', () => {
        window.doorbell.setOption('email', data.data.user.email);
        window.doorbell.refresh();
      });
      window.analytics &&
        window.analytics.identify({
          name: this.user.data.name,
          email: this.user.data.email,
        });
    }
  }

  setNewMapPosition(location) {
    this.mapPosition = {
      radius: this.mapPosition.radius,
      ...location,
    };
  }

  moveMapToCurrentLocation() {
    this.mapPosition = {
      radius: this.mapPosition.radius,
      lat: this.userLocation.lat,
      lng: this.userLocation.lng,
    };
  }

  resetPlaceUnderEdit() {
    this.placeUnderEdit = mobx.toJS(this.selectedPlace) || {
      title: '',
      description: '',
      pictures: [],
      photos: [],
      position: mobx.toJS(this.userLocation),
      categories: ['playground'],
    };
  }

  async savePlace(place) {
    const json = mobx.toJS(place);
    json.lat = place.position.lat;
    json.lng = place.position.lng;
    const data = await api.savePlace(json);
    if (data.success) {
      this.places.data.push(data.data);
      return data.data;
    } else {
      return null;
    }
  }

  requestPlaceDelete(placeId) {
    this.ui.deleteCanceled = false;
    this.ui.deleteNotificationOpened = true;

    const index = _.findIndex(this.places.data, { id: placeId });
    const place = this.places.data[index];
    if (index >= 0) {
      this.places.data.splice(index, 1);
    }

    const userIndex = _.findIndex(this.userPlaces.data, { id: placeId });
    if (userIndex >= 0) {
      this.userPlaces.data.splice(userIndex, 1);
    }

    setTimeout(() => {
      if (this.ui.deleteCanceled) {
        // remove it back
        if (index >= 0) {
          this.places.data.push(place);
        }
        if (userIndex >= 0) {
          this.userPlaces.data.push(place);
        }
        console.log('place remove canceled');
        return;
      }
      console.log('place remove');
      api.deletePlace(placeId);
    }, 6000);
  }

  async reviewAllPlaces() {
    await api.reviewAllPlaces();
    this.reviewPlaces.data = [];
  }

  async savePlaceClassify(place, score) {
    const json = mobx.toJS(place);
    json.lat = place.position.lat;
    json.lng = place.position.lng;

    const res = await api.classifyPlace(json, score);
    console.log('classify', res);
  }
}

export default () => new Store();
