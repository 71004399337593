import React from 'react';
import { inject, observer } from 'mobx-react';
import _ from 'lodash';
import * as mobx from 'mobx';

import Button from '@material-ui/core/Button';
import Dialog from '@material-ui/core/Dialog';
import AppBar from '@material-ui/core/AppBar';
import Toolbar from '@material-ui/core/Toolbar';
import IconButton from '@material-ui/core/IconButton';
import List from '@material-ui/core/List';
import ListItem from '@material-ui/core/ListItem';
import Typography from '@material-ui/core/Typography';
import CloseIcon from '@material-ui/icons/Close';
import Card from '@material-ui/core/Card';
import CardContent from '@material-ui/core/CardContent';
import CardActions from '@material-ui/core/CardActions';
import CardMedia from '@material-ui/core/CardMedia';
import MobileStepper from '@material-ui/core/MobileStepper';
import TextField from '@material-ui/core/TextField';
import Input from '@material-ui/core/Input';

import LinearProgress from '@material-ui/core/LinearProgress';

import ListItemText from '@material-ui/core/ListItemText';
import Checkbox from '@material-ui/core/Checkbox';

import text from '../utils/i18n.js';
import * as api from '../utils/client-api';

import loadMap from '../utils/gmap';
import { redrawMap } from '../utils/map-utils';

import { getIcon } from '../utils/icons';

const CheckboxListItem = ({ checked, title, onClick }) => (
  <ListItem role={undefined} dense button onClick={onClick}>
    <Checkbox checked={checked} tabIndex={-1} disableRipple />
    <ListItemText primary={title} />
  </ListItem>
);

let paddingBottom = 0;
if (CSS.supports('padding-bottom: env(safe-area-inset-bottom)')) {
  let div = document.createElement('div');
  div.style.paddingBottom = 'env(safe-area-inset-bottom)';
  document.body.appendChild(div);
  paddingBottom = parseInt(window.getComputedStyle(div).paddingBottom, 10);
  document.body.removeChild(div);
}

class PlaceEditPopup extends React.Component {
  markers = [];
  componentDidMount() {
    mobx.extendObservable(this, {
      isUploading: false,
      uploadingProgress: 0,
      step: 0,
    });
    loadMap().then(() => {
      this.initMap();
    });

    mobx.reaction(
      () => mobx.toJS(this.props.store.userLocation),
      () => this.updateUserLocation()
    );
    mobx.reaction(
      () => mobx.toJS(this.props.store.selectedPlace),
      () => this.updatePlaceMarker()
    );

    // reset from on new place
    mobx.reaction(
      () => this.props.store.placeUnderEdit,
      (placeUnderEdit) => {
        if (!this.map) {
          return;
        }
        this.map.setCenter(placeUnderEdit.position);
        this.step = 0;
      }
    );
    mobx.reaction(
      () => mobx.toJS(this.props.store.placeUnderEdit),
      () => {
        if (!this.map) {
          return;
        }
        this.updatePlaceMarker();
      }
    );
    mobx.reaction(
      () => mobx.toJS(this.props.store.places.data),
      () => {
        if (!this.map) {
          return;
        }
        // this.updatePlacesMarkers();
      }
    );
  }

  componentDidUpdate() {
    if (this.step === 1) {
      this.initMap();
    }
  }

  handleEnter = () => {
    this.placeMarker = null;
    this.currentLocationMarker = null;
    // this.initMap();
  };
  initMap() {
    if (!this.mapContainer) {
      return;
    }
    this.map = new window.google.maps.Map(this.mapContainer, {
      zoom: 15,
      center: this.props.store.mapPosition,
      streetViewControl: false,
      mapTypeControl: false,
      clickableIcons: false,
      fullscreenControl: false,
    });

    window.google.maps.event.addListener(this.map, 'click', (event) => {
      console.log('click', event);
      this.props.store.placeUnderEdit.position = {
        lat: event.latLng.lat(),
        lng: event.latLng.lng(),
      };
    });

    this.autocomplete = new window.google.maps.places.Autocomplete(
      document.querySelector('#location-search-input')
    );

    window.google.maps.event.addListener(
      this.autocomplete,
      'place_changed',
      () => {
        var place = this.autocomplete.getPlace();
        if (!place.geometry) {
          return;
        }
        this.props.store.placeUnderEdit.position = {
          lng: place.geometry.location.lng(),
          lat: place.geometry.location.lat(),
        };
        this.map.setCenter(this.props.store.placeUnderEdit.position);
      }
    );
    this.updateUserLocation();
    this.updatePlaceMarker();
    // this.updatePlacesMarkers();
  }
  updatePlacesMarkers() {
    this.markers.forEach((marker) => {
      marker.setMap(null);
    });
    this.markers = this.props.store.filteredPlaces.map((place) => {
      var marker = new window.google.maps.Marker({
        position: place.position,
        map: this.map,
        icon: getIcon(place.categories[0]),
      });
      return marker;
    });
  }
  updateUserLocation() {
    if (!this.map) {
      return;
    }
    this.currentLocationMarker =
      this.currentLocationMarker ||
      new window.google.maps.Marker({
        map: this.map,
        title: 'Current location',
        clickable: true,
        icon: {
          path: window.google.maps.SymbolPath.CIRCLE,
          fillColor: '#3C8DF9',
          fillOpacity: 1,
          scale: 7,
          strokeColor: 'rgba(60, 141, 249, 0.4)',
          strokeWeight: 6,
        },
        shadow: null,
        // optimized: false,
        zIndex: 12,
      });

    const location = this.props.store.userLocation;
    this.currentLocationMarker.setPosition(
      new window.google.maps.LatLng(location.lat, location.lng)
    );
  }
  updatePlaceMarker() {
    const { placeUnderEdit } = this.props.store;
    if (placeUnderEdit) {
      this.placeMarker =
        this.placeMarker ||
        new window.google.maps.Marker({
          map: this.map,
          clickable: false,
          position: placeUnderEdit.position,
          optimized: false,
          zIndex: 2,
        });

      this.placeMarker.setMap(this.map);

      this.placeMarker.setPosition(
        new window.google.maps.LatLng(
          placeUnderEdit.position.lat,
          placeUnderEdit.position.lng
        )
      );
    }
  }
  handleFileInputClick = () => {
    this.fileInput.click();
  };
  handleFileInputChange = (e) => {
    this.isUploading = true;
    this.uploadingProgress = 0;
    let uploaded = 0;
    const length = e.target.files.length;
    // window.f7app.showIndicator();
    _.forEach(e.target.files, (file) => {
      const reader = new window.FileReader();
      reader.onload = async (e) => {
        try {
          const json = await api.uploadPhoto(file, (event) => {
            this.uploadingProgress = (event.loaded / event.total) * 100;
          });

          this.props.store.placeUnderEdit.pictures.push(json.picture);
          if (json.picture.lat && json.picture.lng) {
            this.props.store.placeUnderEdit.position = {
              lat: json.picture.lat,
              lng: json.picture.lng,
            };
            this.map.setCenter(this.props.store.placeUnderEdit.position);
          }
        } catch (e) {
          setTimeout(() => {
            throw e;
          });
        }
        // this.props.addForUpload({ src: e.target.result, file, id: uuid.v4() });
        uploaded += 1;
        if (uploaded === length) {
          this.form.reset();
          // window.f7app.hideIndicator();
          this.isUploading = false;
        }
      };
      reader.readAsDataURL(file);
    });
  };

  handleTypeChange(type, e) {
    const { categories } = this.props.store.placeUnderEdit;
    const notChecked = categories.indexOf(type) === -1;
    if (notChecked) {
      categories.push(type);
    } else {
      const index = categories.indexOf(type);
      categories.splice(index, 1);
    }
  }

  handleNextClick = () => {
    this.step += 1;
  };
  handleSaveClick = async () => {
    this.handleClose();
    const place = await this.props.store.savePlace(
      this.props.store.placeUnderEdit
    );
    const isNew = !this.props.store.placeUnderEdit.id;
    this.props.store.places.data.push(place);
    if (place) {
      if (isNew) {
        this.props.store.ui.placeAddedNotificationOpened = true;
      }
      // window.f7app.alert(text('place_add_message'), text('success'));
    } else {
      this.props.store.ui.placeFailedNotificationOpened = true;
      // window.f7app.hideIndicator();
      // window.f7app.alert(text('place_not_add_message'), text('fail'));
    }
  };
  handleClose = () => {
    this.props.store.ui.placeEditOpened = false;
  };
  render() {
    const placeUnderEdit = this.props.store.placeUnderEdit;

    // const stepsTexts = [
    //   text('add_place_image_title'),
    //   text('add_place_map_title'),
    //   text('add_place_type_title'),
    //   text('add_place_info_title')
    // ];
    return (
      <Dialog
        fullScreen
        open={this.props.store.ui.placeEditOpened}
        onEntered={this.handleEnter}
      >
        <AppBar>
          <Toolbar style={{ padding: 0 }}>
            <IconButton
              color="inherit"
              onClick={this.handleClose}
              aria-label="Close"
            >
              <CloseIcon />
            </IconButton>
            <Input
              fullWidth
              id="location-search-input"
              placeholder={text('search')}
              color="inherit"
              style={{
                color: 'white',
                marginRight: '20px',
                borderBottom: '2px solid rgba(255, 255, 255 ,0.5)',
                display: this.step === 1 ? '' : 'none',
              }}
            />
          </Toolbar>
        </AppBar>

        <div
          style={{
            paddingTop: '54px',
            height: 'calc(100vh - 54px - 54px)',
            overflow: 'auto',
          }}
        >
          <div style={{ display: this.step !== 0 ? 'none' : '' }}>
            <Typography style={{ padding: '10px' }}>
              {text('photos_tip')}
            </Typography>
            {this.isUploading && (
              <LinearProgress
                variant="determinate"
                value={this.uploadingProgress * 0.9}
              />
            )}
            <Card
              className={this.isUploading ? 'animate-loader' : ''}
              style={{ margin: '10px' }}
              onClick={this.handleFileInputClick}
            >
              <CardContent>
                <form
                  style={{ display: 'none' }}
                  ref={(node) => (this.form = node)}
                >
                  <input
                    type="file"
                    accept="image/*"
                    multiple
                    ref={(node) => (this.fileInput = node)}
                    onChange={this.handleFileInputChange}
                  />
                </form>
                <div style={{ textAlign: 'center' }}>
                  <img src="./image.svg" width="150" alt="Place" />
                  <br />
                  <Typography>
                    {this.isUploading
                      ? text('uploading')
                      : text('tap_to_add_image')}
                  </Typography>
                </div>
              </CardContent>
            </Card>

            <div>
              {placeUnderEdit.pictures.map((picture, i) => (
                <Card style={{ margin: '10px', marginBottom: '20px' }} key={i}>
                  <CardMedia
                    image={picture.secure_url}
                    title="Place picture"
                    style={{ paddingTop: '56.25%' }}
                  />
                  <CardActions>
                    <Button
                      onClick={() => {
                        placeUnderEdit.pictures.splice(i, 1);
                      }}
                      variant="contained"
                      color="secondary"
                    >
                      {text('delete')}
                    </Button>
                  </CardActions>
                </Card>
              ))}
            </div>
          </div>

          <List style={{ display: this.step !== 3 ? 'none' : '' }}>
            <ListItem>
              <TextField
                fullWidth
                id="name"
                placeholder={text('type_place_name')}
                value={placeUnderEdit.title}
                onChange={(e) => {
                  placeUnderEdit.title = e.target.value;
                }}
                margin="normal"
              />
            </ListItem>
            <ListItem>
              <TextField
                fullWidth
                id="description"
                placeholder={text('type_place_description')}
                value={placeUnderEdit.description}
                onChange={(e) => {
                  placeUnderEdit.description = e.target.value;
                }}
                margin="normal"
              />
            </ListItem>
          </List>

          <div style={{ display: this.step !== 2 ? 'none' : '' }}>
            <Typography
              align="center"
              variant="h4"
              style={{ paddingTop: '20px' }}
            >
              {text('place_type')}:
            </Typography>
            <List>
              <CheckboxListItem
                title={text('park')}
                checked={_.includes(placeUnderEdit.categories, 'park')}
                onClick={this.handleTypeChange.bind(this, 'park')}
              />
              <CheckboxListItem
                title={text('playground')}
                checked={_.includes(placeUnderEdit.categories, 'playground')}
                onClick={this.handleTypeChange.bind(this, 'playground')}
              />
              <CheckboxListItem
                title={text('entertainment')}
                checked={_.includes(placeUnderEdit.categories, 'entertainment')}
                onClick={this.handleTypeChange.bind(this, 'entertainment')}
              />
              <CheckboxListItem
                title={text('cafe')}
                checked={_.includes(placeUnderEdit.categories, 'cafe')}
                onClick={this.handleTypeChange.bind(this, 'cafe')}
              />
              <CheckboxListItem
                title={text('other')}
                checked={_.includes(placeUnderEdit.categories, 'other')}
                onClick={this.handleTypeChange.bind(this, 'other')}
              />
            </List>
          </div>

          <div
            style={{
              position: 'relative',
              display: this.step !== 1 ? 'none' : '',
              height: '100%',
            }}
          >
            <div
              ref={(node) => {
                this.mapContainer = node;
              }}
              style={{ height: '100%' }}
            />
          </div>
        </div>
        <MobileStepper
          style={{
            paddingBottom: paddingBottom + 'px',
          }}
          variant="progress"
          steps={4}
          position="bottom"
          activeStep={this.step}
          nextButton={
            (this.step === 3 && (
              <Button
                onClick={this.handleSaveClick}
                style={{
                  pointerEvents: this.isUploading ? 'none' : '',
                }}
              >
                {text('save')}
              </Button>
            )) ||
            (this.step !== 3 && (
              <Button
                onClick={this.handleNextClick}
                style={{
                  pointerEvents: this.isUploading ? 'none' : '',
                }}
              >
                {this.isUploading ? text('wait') : text('next')}
              </Button>
            ))
          }
        />
      </Dialog>
    );
  }
}

export default inject('store')(observer(PlaceEditPopup));
