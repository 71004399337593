let loadPromise = null;

const API_KEY = `AIzaSyDzAU3Iq__IhNylzaEyQGG_b9VsXAcNeGY`;

export default function loadMap() {
  if (loadPromise) {
    return loadPromise;
  }
  loadPromise = new Promise((resolve) => {
    var script = document.createElement('script');
    script.src = `https://maps.googleapis.com/maps/api/js?key=${API_KEY}&libraries=places,visualization,geometry`;
    script.onload = () => {
      console.log('map loaded');
      resolve();
    };
    document.head.appendChild(script);
  });
  return loadPromise;
}
