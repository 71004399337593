import React from 'react';
import { inject, observer } from 'mobx-react';

import Dialog from '@material-ui/core/Dialog';
import Button from '@material-ui/core/Button';
import Typography from '@material-ui/core/Typography';

import text from '../utils/i18n.js';
import { isPhonegap } from '../utils/env';

const loginUrl = isPhonegap()
  ? 'https://gowithkid.com/auth/facebook'
  : 'http://localhost:4000/auth/facebook';

const vkUrl = isPhonegap()
  ? 'https://gowithkid.com/auth/vkontakte'
  : 'http://localhost:4000/auth/vkontakte';

class LoginPopup extends React.Component {
  handleLoginClick = (e) => {
    e.preventDefault();
    const href = e.target.href || e.target.parentNode.href;
    if (isPhonegap()) {
      var iab = window.cordova.InAppBrowser.open(href, '_blank');

      iab.addEventListener('loadstart', (event) => {
        const isDone = event.url.indexOf('gowithkid.com/app') >= 0;
        iab.executeScript({ code: 'document.cookie' }, function (cookie) {
          alert(cookie);
        });
        if (isDone) {
          iab.close();
          this.props.store.loadUserData();
          this.props.store.ui.loginOpened = false;
        }
      });
    } else {
      e.preventDefault();
      window.location = href;
    }
  };
  render() {
    return (
      <Dialog fullScreen open={this.props.store.ui.loginOpened}>
        <div
          style={{
            textAlign: 'center',
            paddingTop: '30px',
          }}
        >
          <Typography>{text('login')}</Typography>
          <Typography>{text('please_login_text')}</Typography>
          <div
            style={{
              paddingTop: '40px',
              paddingBottom: '40px',
            }}
          >
            <Button href={loginUrl} onClick={this.handleLoginClick}>
              Facebook
            </Button>
            <Button href={vkUrl} onClick={this.handleLoginClick}>
              Vkontakte
            </Button>
          </div>
          <p>
            <Button
              onClick={() => {
                this.props.store.ui.loginOpened = false;
              }}
            >
              {text('cancel')}
            </Button>
          </p>
        </div>
      </Dialog>
    );
  }
}

export default inject('store')(observer(LoginPopup));
