export function isPhonegap() {
  return (
    document.URL.indexOf('http://') === -1 &&
    document.URL.indexOf('https://') === -1
  );
}

const WELCOME_NAMESPACE = 'welcome_showed';
export function isWelcomeShowed() {
  return !!localStorage.getItem(WELCOME_NAMESPACE);
}

export function markWelcomeAsShowed() {
  localStorage.setItem(WELCOME_NAMESPACE, 'true');
}

const GEO_NAMESPACE = 'geo_asked';
export function isAskedForGeo() {
  return !!localStorage.getItem(GEO_NAMESPACE);
}

export function markGeoAsAsked() {
  localStorage.setItem(GEO_NAMESPACE, 'true');
}

const LOGGED_NAMESPACE = 'was_logged';
export function wasLogged() {
  return !!localStorage.getItem(LOGGED_NAMESPACE);
}

export function markAsWasLogged() {
  localStorage.setItem(LOGGED_NAMESPACE, 'true');
}
